import React from 'react';
import styled, { css } from 'styled-components';
import SearchIcon from 'icons/src/Search';
import TimesIcon from 'icons/src/Times';
import Options from 'gatsby-theme-vehicles/src/components/search/options';
import staticWhere from 'gatsby-theme-vehicles/src/where';
import { OptionTitle } from '../filter-options';

const Engine = ({ filterIsOpen, setFilterOpen }) => (
  <div>
    <FilterOverlay
      onClick={() => setFilterOpen(false)}
      filterIsOpen={filterIsOpen}
    />
    <FilterWrapper filterIsOpen={filterIsOpen}>
      <FilterTitle>
        <span>Zoek opties</span>
        {filterIsOpen
          ? (
            <button
              css={`
                padding: 0;
                background: none;
                border: none;
              `}
              type="button"
              onClick={() => setFilterOpen(false)}
            >
              <TimesIcon />
            </button>
          )
          : <SearchIcon />}
      </FilterTitle>
      {!staticWhere.make && (
        <Options
          type="select"
          title="Merk"
          field="make"
          defaultOptionTitle="Alle merken"
        />
      )}
      <Options
        type="select"
        title="Model"
        field="model"
        defaultOptionTitle="Alle modellen"
        // when empty title?
      />

      <OptionTitle>Prijs:</OptionTitle>
      <OptionWrapper>
        <Options
          type="select"
          field="price_min"
          defaultOptionTitle="vanaf"
          options={[{
            name: '25.000',
            value: 25000,
          }, {
            name: '50.000',
            value: 50000,
          }, {
            name: '75.000',
            value: 75000,
          }, {
            name: '100.000',
            value: 100000,
          }, {
            name: '250.000',
            value: 250000,
          }]}
        />
        <Options
          type="select"
          field="price_max"
          defaultOptionTitle="tot"
          options={[{
            name: '50.000',
            value: 50000,
          }, {
            name: '75.000',
            value: 75000,
          }, {
            name: '100.000',
            value: 100000,
          }, {
            name: '250.000',
            value: 250000,
          }, {
            name: '500.000',
            value: 500000,
          }]}
        />
      </OptionWrapper>

      <OptionTitle>Bouwjaar:</OptionTitle>
      <OptionWrapper>
        <Options
          type="select"
          field="buildyear_min"
          defaultOptionTitle="vanaf"
        />
        <Options
          type="select"
          field="buildyear_max"
          defaultOptionTitle="tot"
        />
      </OptionWrapper>

      <OptionTitle>Tellerstand:</OptionTitle>
      <OptionWrapper>
        <Options
          type="select"
          field="odometer_min"
          defaultOptionTitle="vanaf"
          options={[{
            name: '25.000',
            value: 25000,
          }, {
            name: '50.000',
            value: 50000,
          }, {
            name: '75.000',
            value: 75000,
          }, {
            name: '100.000',
            value: 100000,
          }, {
            name: '175.000',
            value: 175000,
          }, {
            name: '250.000',
            value: 250000,
          }, {
            name: '500.000',
            value: 500000,
          }]}
        />
        <Options
          type="select"
          field="odometer_max"
          defaultOptionTitle="tot"
          options={[{
            name: '25.000',
            value: 25000,
          }, {
            name: '50.000',
            value: 50000,
          }, {
            name: '75.000',
            value: 75000,
          }, {
            name: '100.000',
            value: 100000,
          }, {
            name: '175.000',
            value: 175000,
          }, {
            name: '250.000',
            value: 250000,
          }, {
            name: '500.000',
            value: 500000,
          }]}
        />
      </OptionWrapper>

      <Options
        type="select"
        title="Carrosserie"
        field="body"
        defaultOptionTitle="Alle carrosserieën"
      />
      <Options
        type="select"
        title="Brandstof"
        field="fuel"
        defaultOptionTitle="Alle brandstoffen"
      />
      <Options
        type="select"
        title="Transmissie"
        field="transmission"
        defaultOptionTitle="Alle transmissies"
      />
      <Options
        type="select"
        title="Kleur"
        field="color"
        defaultOptionTitle="Alle kleuren"
      />
    </FilterWrapper>
  </div>
);

const FilterTitle = styled.h2`
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;

  svg {
    width: 1.5rem;
  }
`;
const FilterOverlay = styled.div`
  ${({ filterIsOpen }) => filterIsOpen && css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: .2;
    z-index: 10;
  `}
`;
const FilterWrapper = styled.div`
  z-index: 40;
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 100%;
  background-color: ${({ theme }) => theme.grayLightColor};
  border-top: .25rem solid black;
  padding: 1rem;
  transition: .2s;
  max-height: 100vh;
  overflow-y: scroll;
  ${({ filterIsOpen }) => filterIsOpen && css`
    left: 25%;
    @media all and (min-width: ${({ theme }) => theme.breakingpoints.md}) {
      left: 50%;
    }
  `}
  @media all and (min-width: ${({ theme }) => theme.breakingpoints.lg}) {
    max-height: initial;
    overflow-y: initial;
    position: initial;
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
  }
`;
const OptionWrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: .5rem;
`;

export default Engine;
