import useSearch from './use-search';

const sortValues = {
  makeDesc: 'make:desc',
  makeAsc: 'make:asc',
  latest: 'updatedAt:desc',
  newest: 'createdAt:desc',
  oldest: 'createdAt:asc',
  priceDesc: 'lowest_price:desc',
  priceAsc: 'lowest_price:asc',
  buildyearDesc: 'buildyear:desc',
  buildyearAsc: 'buildyear:asc',
};

const useSort = () => {
  const {
    sort,
    setSort,
  } = useSearch();
  const handleSortChange = ({ target: { value } }) => setSort(value);

  return {
    sort,
    sortValues,
    handleSortChange,
  };
};

export default useSort;
