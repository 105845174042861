import React from 'react';
import styled from 'styled-components';
import { Select } from './form-elements';

const Options = ({
  type = 'radio',
  title,
  options = [],
  value,
  handler,
  defaultOptionTitle,
}) => options.length > 0 && (
  <Option>
    {title && (
      <OptionTitle>{title}:</OptionTitle>
    )}
    {type === 'checkbox' && options.map(({ value: v, name, count }, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <RadioItem key={`${name}-${i}`}>
        <input checked={(value || []).includes(v || name)} name={title} type="checkbox" value={v || name} onChange={handler} />
        <span>{name}{count && ` (${count})`}</span>
      </RadioItem>
    ))}
    {type === 'radio' && options.map(({ value: v, name, count }) => (
      <RadioItem key={name}>
        <input checked={value === name} name={title} type="radio" value={v || name} onChange={handler} />
        <span>{name}{count && ` (${count})`}</span>
      </RadioItem>
    ))}
    {type === 'select' && (
      <Select name={title} value={value || ''} onChange={handler}>
        <option value="">{defaultOptionTitle || '...'}</option>
        {options.map(({ value: v, name, count }) => (
          <option key={name} value={v || name}>{name} {count && `(${count})`}</option>
        ))}
      </Select>
    )}
  </Option>
);

const RadioItem = styled.label`
  display: block;
  position: relative;
  padding-left: 1.25rem;
  &:not(:last-of-type) {
    margin-bottom: .5rem;
  }
  input {
    position: absolute;
    top: .2rem;
    left: 0;
  }
`;
export const OptionTitle = styled.h3`
  font-size: 1rem;
  margin-bottom: .5rem;
  color: black;
`;
const Option = styled.div`
  font-weight: 500;
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export default Options;
