import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ListUl from 'icons/src/ListUl';
import Th from 'icons/src/Th';
import Sliders from 'icons/src/Sliders';
import Layout from 'gatsby-theme-core/src/layout/layout';
import Container from 'gatsby-theme-core/src/components/container';
import useSearch from 'gatsby-theme-vehicles/src/components/search/hooks/use-search';
import useSort from 'gatsby-theme-vehicles/src/components/search/hooks/use-sort';
import useLayout from 'gatsby-theme-vehicles/src/components/search/hooks/use-layout';

import { Select } from '../../components/form-elements';
import Results from '../../components/stock-page/results';
import Engine from '../../components/stock-page/engine';

const StockPage = ({ page }) => {
  const {
    totalNumberOfVehicles,
  } = useSearch();
  const {
    handleSortChange,
    sortValues,
    sort,
  } = useSort();
  const [layout, setLayout] = useLayout();
  const [filterIsOpen, setFilterOpen] = useState(false);

  return (
    <Layout {...page}>
      <Container>
        <Wrapper>
          <div>
            <Header>
              <Titles>
                <div>
                  <h1>Aanbod</h1>
                  <h2>{totalNumberOfVehicles ?? '...'} matches</h2>
                </div>
                <FilterButton
                  type="button"
                  onClick={() => setFilterOpen(true)}
                >
                  Verfijn <Sliders />
                </FilterButton>
              </Titles>
              <OrderAndLayout>
                <SelectLabel>Sorteren:</SelectLabel>
                <Select
                  dark
                  small
                  value={sort}
                  onChange={handleSortChange}
                >
                  <option value={sortValues.makeAsc}>
                    Merk: A - Z
                  </option>
                  <option value={sortValues.makeDesc}>
                    Merk: Z - A
                  </option>
                  <option value={sortValues.priceAsc}>
                    Prijs: laagte eerst
                  </option>
                  <option value={sortValues.priceDesc}>
                    Prijs: hoogste eerst
                  </option>
                  <option value={sortValues.buildyearAsc}>
                    Bouwjaar: laagste eerst
                  </option>
                  <option value={sortValues.buildyearDesc}>
                    Bouwjaar: hoogste eerst
                  </option>
                  <option value={sortValues.latest}>
                    Laatst gewijzigd
                  </option>
                </Select>
                <LayoutButtonWrapper>
                  <LayoutButton
                    active={layout === 'column'}
                    onClick={() => setLayout('column')}
                  >
                    <Th />
                  </LayoutButton>
                  <LayoutButton
                    active={layout === 'row'}
                    onClick={() => setLayout('row')}
                  >
                    <ListUl />
                  </LayoutButton>
                </LayoutButtonWrapper>
              </OrderAndLayout>
            </Header>

            <Results />
          </div>
          <Engine
            filterIsOpen={filterIsOpen}
            setFilterOpen={setFilterOpen}
          />
        </Wrapper>
      </Container>
    </Layout>
  );
};

const Titles = styled.div`
  h1 {
    color: ${({ theme }) => theme.h1Color};
    font-size: 1.75rem;
  }
  h2 {
    color: ${({ theme }) => theme.primaryColor};
    font-size: 1.25rem;
    font-weight: 700;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SelectLabel = styled.span`
  display: none;
  padding-right: .75rem;
  font-weight: 400;
  font-size: .75rem;
  text-transform: uppercase;
  @media all and (min-width: ${({ theme }) => theme.breakingpoints.lg}) {
    display: inline-block;
  }
`;
const activeLayout = css`
  background-color: ${({ theme }) => theme.grayLightColor};
  color: ${({ theme }) => theme.primaryColor};
`;
const LayoutButton = styled.button`
  padding: 0;
  border: none;
  margin-left: .5rem;
  background-color: transparent;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: .2s;
  color: ${({ theme }) => theme.grayColor};
  ${({ active }) => active && css`
    ${activeLayout}
  `};
  &:hover {
    ${activeLayout}
  }
  svg {
    height: 1rem;
  }
`;
const LayoutButtonWrapper = styled.div`
  display: flex;
`;
const OrderAndLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  @media all and (min-width: ${({ theme }) => theme.breakingpoints.lg}) {
    justify-content: normal;
    margin-top: 0;
  }
`;
const Header = styled.section`
  border-bottom: 1px solid ${({ theme }) => theme.grayLightColor};
  margin-bottom: ${(props) => props.theme.grid.columnGap};
  padding-bottom: 1rem;
  @media all and (min-width: ${({ theme }) => theme.breakingpoints.lg}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const FilterButton = styled.button`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.primaryColor};
  padding: .5rem 1rem;
  border: none;
  color: white;
  font-size: .80rem;
  cursor: pointer;
  @media all and (min-width: ${({ theme }) => theme.breakingpoints.lg}) {
    display: none;
  }
  svg {
    width: 1rem;
    margin-left: .5rem;
  }
`;
const Wrapper = styled.div`
  padding: 2rem 0;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: minmax(0, 1fr);
  grid-column-gap: ${(props) => props.theme.grid.columnGap};
  @media all and (min-width: ${({ theme }) => theme.breakingpoints.lg}) {
    grid-template-columns: minmax(0, 1fr) 16rem;
  }
`;

export default StockPage;
