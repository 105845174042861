import React from 'react';
import { graphql } from 'gatsby';
import SearchProvider from '../components/search';
import StockPage from '../layouts/stock-page';

export const pageQuery = graphql`
  fragment StockPageFiltersFragment on StrapiVehiclesVehiclesConnection {
    totalCount

    makeModels: group(field: make) {
      field
      fieldValue
      totalCount
      models:group(field: model) {
        field
        fieldValue
        totalCount
      }
    }

    bodies: group(field: body) {
      field
      fieldValue
      totalCount
    }

    fuels: group(field: fuel) {
      field
      fieldValue
      totalCount
    }

    transmissions: group(field: transmission) {
      field
      fieldValue
      totalCount
    }

    tax_types: group(field: tax_type) {
      field
      fieldValue
      totalCount
    }

    buildyears: group(field: buildyear) {
      field
      fieldValue
      totalCount
    }

    base_colors: group(field: base_color) {
      field
      fieldValue
      totalCount
    }

    colors: group(field: color) {
      field
      fieldValue
      totalCount
    }

    energylabels: group(field: energylabel) {
      field
      fieldValue
      totalCount
    }
  }

  query ACMVehiclesStockPage(
    $id: String!,
    $limit: Int!,
    $gatsbySort: StrapiVehiclesVehiclesSortInput,
    $gatsbyFilter: StrapiVehiclesVehiclesFilterInput,
    $withStaticVehicles: Boolean!,
  ) {
    filterData: allStrapiVehiclesVehicles(filter: $gatsbyFilter) {
      ...StockPageFiltersFragment
    }

    staticVehicles: allStrapiVehiclesVehicles(limit: $limit, sort: $gatsbySort, filter: $gatsbyFilter) @include(if: $withStaticVehicles) {
      nodes {
        id
        ...VehiclePreview
      }
    }

    page: strapiPagesPages(id: { eq: $id }) {
      id
      title
      content: childStrapiPagesPagesContent {
        childMarkdownRemark {
          html
        }
      }
      ...PagesPageHead
    }
  }
`;

const StockPageTemplate = ({
  pageContext,
  data: {
    filterData,
    staticVehicles,
    page,
  },
}) => (
  <SearchProvider
    filterData={filterData}
    staticVehicles={staticVehicles.nodes}
    limit={pageContext.limit}
    defaultSort={pageContext.sort}
    defaultWhere={pageContext.where}
    possibleLayouts={pageContext.possibleLayouts}
    slugPrefix={pageContext.slugPrefix}
    layoutKey={pageContext.title}
  >
    <StockPage page={page} />
  </SearchProvider>
);

export default StockPageTemplate;
