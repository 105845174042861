import React from 'react';
import styled, { css } from 'styled-components';
import Button from 'gatsby-theme-core/src/components/button';
import useSearch from 'gatsby-theme-vehicles/src/components/search/hooks/use-search';
import useLayout from 'gatsby-theme-vehicles/src/components/search/hooks/use-layout';
import VehiclePreview from 'gatsby-theme-vehicles/src/components/preview';
import Bubbles from './bubbles';

const Results = () => {
  const {
    vehicles,
    showLoading,
    loading,
    moreVehiclesAvailable,
    loadMoreVehicles,
  } = useSearch();
  const [layout] = useLayout();
  return (
    <div>
      <Bubbles />
      {showLoading && (
        <div>Loading...</div>
      )}
      <VehicleWrapper layout={layout}>
        {vehicles.filter(Boolean).map((vehicle) => (
          <VehiclePreview key={vehicle.id} layout={layout} vehicle={vehicle} />
        ))}
      </VehicleWrapper>
      {moreVehiclesAvailable > 0 && (
        <LoadMoreButtonWrapper>
          {loading && <div>Meer aan het laden...</div>}
          {!loading && (
            <Button onClick={loadMoreVehicles}>
              Laad meer ({moreVehiclesAvailable})
            </Button>
          )}
        </LoadMoreButtonWrapper>
      )}
    </div>
  );
};

const VehicleWrapper = styled.div`
  ${({ layout }) => layout === 'column' && css`
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    grid-gap: ${(props) => props.theme.grid.columnGap};
    @media all and (min-width: ${({ theme }) => theme.breakingpoints.sm}) {
      grid-template-columns: 1fr 1fr;
    }
    @media all and (min-width: ${({ theme }) => theme.breakingpoints.xl}) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  `}
`;

const LoadMoreButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 2rem 0 1rem;
  > ${Button} {
    padding: 0 2rem;
  }
`;
export default Results;
