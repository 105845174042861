import React from 'react';
import styled, { css } from 'styled-components';
import ChevronDown from 'icons/src/ChevronDown';

const SelectElement = styled.select`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.grayLightColor};
  min-height: 2.75rem;
  width: 100%;
  padding: 0 1.5rem 0 .5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-ms-expand {
    display: none;
  }
  outline: none;
  &:focus {
    border-color: black;
  }
  &:disabled {
    opacity: 0.9;
    cursor: not-allowed;
  }
  ${({ small }) => small && css`
    min-height: 2rem;
    @supports not (-webkit-touch-callout: none) {
      /* CSS for other than iOS devices */
      font-size: .75rem;
    }
  `}
  ${({ dark }) => dark && css`
    background-color: ${({ theme }) => theme.grayLightColor};
  `}
`;

const Icon = styled(ChevronDown)`
  width: .75rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: .5rem;
  pointer-events: none;
`;

const Wrapper = styled.div`
  position: relative;
  color: ${({ theme }) => theme.fontColor};
`;

export const Select = styled((props) => (
  <Wrapper className={props.className}>
    <Icon />
    <SelectElement {...props}>
      {props.children}
    </SelectElement>
  </Wrapper>
))`
`;

export default null;
