import React from 'react';
import styled, { css } from 'styled-components';
import useSearch from 'gatsby-theme-vehicles/src/components/search/hooks/use-search';
import Times from 'icons/src/Times';

const Bubbles = () => {
  const {
    where,
    keys,
    setWhere,
    resetFilters,
} = useSearch();
  const currentFilters = Object.values(where).filter(Boolean);
  if (currentFilters.length === 0) {
    return null;
  }
  return (
    <ResetButtonsWrapper>
      {Object.entries(where).map(([key, value]) => value && (
        <ResetButton
          key={key}
          type="button"
          onClick={() => setWhere({ ...where, [key]: undefined })}
        >
          <Times />
          {(keys[key] || key).includes('_gte') && '> '}
          {(keys[key] || key).includes('_lt') && '< '}
          {value}
        </ResetButton>
      ))}
      <ResetAllButton type="button" onClick={resetFilters}>
        <Times />
        Reset filters
      </ResetAllButton>
    </ResetButtonsWrapper>
  );
};

const resetStyles = css`
  border: none;
  font-weight: 600;
  padding: .5rem 1rem;
  margin-bottom: .75rem;
  font-weight: 600;
  font-size: .75rem;
  cursor: pointer;
  flex: 0 0 auto;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  ${Times} {
    width: .6rem;
    margin-right: .5rem;
  }
`;
const ResetAllButton = styled.button`
  ${resetStyles}
  background-color: transparent;
  color: ${({ theme }) => theme.primaryColor};
  border: none;
  transition: .2s;
  &:hover {
    color: ${({ theme }) => theme.fontColor};
  }
`;
const ResetButton = styled.button`
  ${resetStyles}
  margin-right: .75rem;
  background-color: ${({ theme }) => theme.grayLightColor};
  border-top: .15rem solid black;
  transition: .2s;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
    border-top-color: ${({ theme }) => theme.primaryColor};
  }
`;
const ResetButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100vw;
  margin-left: -${({ theme }) => theme.grid.containerGap};
  margin-right: -${({ theme }) => theme.grid.containerGap};
  ${ResetAllButton} {
    order: 1;
  }
  ${ResetButton} {
    order: 2;
  }
  @media all and (min-width: ${({ theme }) => theme.breakingpoints.md}) {
    display: block;
    flex-wrap: initial;
    overflow-x: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
`;

export default Bubbles;
