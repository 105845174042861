import useSearch from './use-search';

const useLayout = () => {
  const { layout, setLayout } = useSearch();
  return [
    layout,
    setLayout,
  ];
};

export default useLayout;
